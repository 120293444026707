import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

import Layout from '../layout'
import config from '../../data/SiteConfig'
import PhotosetListing from '../components/PhotosetListing'
import SEO from '../components/SEO'

export default function Index() {
  const { profilePhoto, allContentfulPhotosets } = useStaticQuery(graphql`
    query indexQuery {
      profilePhoto: file(absolutePath: { regex: "/profile-photo.jpg/" }) {
        childImageSharp {
          fixed(
            width: 350
            height: 300
            quality: 80
            traceSVG: { background: "#fbfafc", color: "#c9f7ff" }
          ) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
      allContentfulPhotosets(
        limit: 4
        sort: { order: DESC, fields: createdAt }
      ) {
        edges {
          node {
            cover {
              localFile {
                childImageSharp {
                  fixed(
                    width: 325
                    height: 250
                    quality: 80
                    traceSVG: { background: "#fbfafc", color: "#c9f7ff" }
                  ) {
                    ...GatsbyImageSharpFixed_tracedSVG
                  }
                }
              }
            }
            id
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO />
      <div className="container">
        <div className="lead">
          <div className="profile">
            <Image
              fixed={profilePhoto.childImageSharp.fixed}
              title={config.siteTitle}
              alt={config.siteTitleAlt}
              className="photo"
            />
          </div>
          <div className="elevator">
            <h1>
              I'm Air <span role="img">{`❄️`}</span>
            </h1>
            <p>
              I'm a medical intern <span role="img">{`👩‍⚕️`}</span>. My website
              about photosets, journey blogs, and stuff.
            </p>
          </div>
        </div>
      </div>

      <div className="container front-page">
        <section className="section">
          <h2>
            Latest photosets <span role="img">{`📷`}</span>
            <Link to="/photosets" className="view-all">
              View all
            </Link>
          </h2>
          <PhotosetListing photosets={allContentfulPhotosets} />
        </section>
      </div>
    </Layout>
  )
}
